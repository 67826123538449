<template>
  <section class="all-cases-view" id="all-approval-pending-cases">
    <div class="navbarComponent">
      <div class="container-default">
        <div class="listings-navbar">
          <el-row>
            <el-col :lg="7">
              <ul class="d-flex list-items-group-15 barcode-print">
                <li>
                  <div class="form-element input-with-icon search-input-lg">
                    <el-input
                      placeholder="Accession / Patient Name"
                      v-model="search_string"
                      @input="getAutoCompleteCases()"
                      :clearable="true"
                    ></el-input>
                    <span>
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid icon"
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </el-col>
            <el-col :lg="17">
              <ul
                class="d-flex flex-wrap justify-content-end list-items-group-15"
              >
                <li>
                  <div
                    class="form-element search-with-filter-input-without-icon"
                  >
                    <el-select
                      v-model="searchBy"
                      clearable
                      filterable
                      default-first-option
                      :reserve-keyword="false"
                      placeholder="Select Search"
                      @change="clearInputSearch"
                    >
                      <el-option
                        v-for="item in searchOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                </li>

                <li v-if="searchBy == 'Patient DOB'">
                  <el-date-picker
                    format="MM-DD-YYYY"
                    :disabled-date="disabledDate"
                    v-model="date_of_birth"
                    @change="fetchAllPendingCases()"
                    placeholder="Select DOB"
                    :clearable="true"
                  ></el-date-picker>
                </li>
                <li v-else-if="searchBy == 'hospital'">
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="hospital"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      @change="handleCurrentChange(1)"
                      @focus="fetchAllHospitals"
                      :loading="hospitalsLoading"
                    >
                      <el-option
                        v-for="hospital in hospitalsData.data"
                        :key="hospital"
                        :label="hospital.name"
                        :value="hospital._id"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li v-else-if="searchBy == 'ordering_physician'">
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="physician"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      @change="handleCurrentChange(1)"
                      @focus="fetchAllPhysicians"
                      :loading="physiciansLoading"
                    >
                      <el-option
                        v-for="physician in physiciansData.data"
                        :key="physician"
                        :label="physician.name"
                        :value="physician._id"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li v-else-if="searchBy == 'case_types'">
                  <div
                    class="form-element search-with-filter-input"
                    v-if="
                      getAuthenticatedUser.user_type == 'LAB_TECHNOLOGIST' &&
                      getAuthenticatedUser.allowed_case_types
                    "
                  >
                    <el-select
                      v-model="caseType"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      :reserve-keyword="false"
                      @change="handleCurrentChange(1)"
                    >
                      <el-option
                        v-for="caseType in getUserBasedCaseTypes(caseTypes)"
                        :key="caseType"
                        :label="caseType.label"
                        :value="caseType.value"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                  <div class="form-element search-with-filter-input" v-else>
                    <el-select
                      v-model="caseType"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      :reserve-keyword="false"
                      @change="handleCurrentChange(1)"
                    >
                      <el-option
                        v-for="caseType in getLabBasedCaseTypes(caseTypes)"
                        :key="caseType"
                        :label="caseType.label"
                        :value="caseType.value"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="container-default" style="padding-top: 0">
      <div class="primary-content-view-new" v-loading="loading">
        <div
          class="listings listings__table listings__all-cases"
          id="all-cases-listings"
        >
          <el-table
            :data="casesTable.data"
            height="calc(100vh - 200px)"
            @sort-change="onChangeSort"
            :default-sort="{
              prop: orderBy,
              order: orderType == 'asc' ? 'ascending' : 'descending',
            }"
          >
            <el-table-column
              fixed="left"
              label="PATIENT NAME"
              prop="patient_info.first_name"
              min-width="180"
              sortable="custom"
            >
              <template v-slot="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="getPatientNameByInfo(scope.row.patient_info)"
                  @click="
                    copyTitle(getPatientNameByInfo(scope.row.patient_info))
                  "
                >
                  <span class="text-center ordering_facility-value">{{
                    getPatientNameByInfo(scope.row.patient_info)
                  }}</span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="PATIENT DOB"
              prop="date_of_birth"
              min-width="100"
            >
              <template v-slot="scope">{{
                formatDate(scope.row.patient_info.date_of_birth)
              }}</template>
            </el-table-column>
            <el-table-column
              label="Case Type"
              min-width="125"
              prop="case_types"
              sortable="custom"
            >
              <template #default="scope">
                <div
                  class="case-type"
                  v-if="scope.row.case_types && scope.row.case_types.length > 1"
                >
                  <el-dropdown>
                    <span class="case-data-type">
                      {{ getCaseTypes(scope.row.case_types)[0] }}

                      <img
                        src="@/assets/images/case-type-arrow.svg"
                        alt="icon"
                      />
                    </span>

                    <template #dropdown>
                      <el-dropdown-menu class="case-type-dropdown">
                        <el-dropdown-item
                          class="cases-view"
                          v-for="(caseType, index) of getCaseTypes(
                            scope.row.case_types
                          )"
                          :key="index"
                        >
                          {{ caseType }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <div v-else class="case-type">
                  <span class="case-data-type">
                    {{ getCaseTypes(scope.row.case_types)[0] }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="ordering_facility"
              label="ORDERING FACILITIES"
              min-width="215"
            >
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="scope.row.hospital ? scope.row.hospital.name : '--'"
                >
                  <span class="text-center ordering_facility-value">{{
                    scope.row.hospital ? scope.row.hospital.name : "--"
                  }}</span>
                </el-button>
              </template>
            </el-table-column>

            <el-table-column label="Physician" min-width="180">
              <template v-slot="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="
                    scope.row.ordering_physician
                      ? scope.row.ordering_physician.name.includes('Dr')
                        ? scope.row.ordering_physician.name
                        : 'Dr ' + scope.row.ordering_physician.name
                      : '--'
                  "
                >
                  <span class="text-center ordering_facility-value">
                    {{
                      scope.row.ordering_physician
                        ? scope.row.ordering_physician.name.includes("Dr")
                          ? scope.row.ordering_physician.name
                          : "Dr " + scope.row.ordering_physician.name
                        : "--"
                    }}
                  </span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="Collected On"
              min-width="130"
              prop="collection_date"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.collection_date" class="d-flex">
                  <div class="date pr-10">
                    {{
                      momentWithTimezone(
                        scope.row.collection_date,
                        "MM-DD-yyyy"
                      )
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Received On"
              min-width="130"
              prop="received_date"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.received_date" class="d-flex">
                  <div class="date pr-10">
                    {{
                      momentWithTimezone(scope.row.received_date, "MM-DD-yyyy")
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="ACCESSION ID"
              prop="accession_id"
              min-width="140"
              sortable="custom"
            >
              <template #default="scope">
                <div class="name-accession">
                  <span
                    id="accession-id"
                    @click="goToCaseView(scope.row.case_id)"
                    >{{ scope.row.accession_id }}
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              label="STATUS"
              prop="status"
              min-width="140"
              sortable="custom"
            >
              <template #default="scope">
                <div class="column-status" v-if="scope.row.status">
                  <span
                    class="el-dropdown-link"
                    v-if="scope.row.status == 'COMPLETE'"
                  >
                    <el-tag :type="getStatusType(scope.row.status)"
                      >APPROVED</el-tag
                    >
                  </span>
                  <span
                    class="el-dropdown-link"
                    v-else-if="scope.row.status == 'ACTIVE'"
                  >
                    <el-tag :type="getStatusType(scope.row.status)"
                      >PENDING</el-tag
                    >
                  </span>
                  <span
                    class="el-dropdown-link"
                    v-else-if="scope.row.status == 'INITIATE'"
                  >
                    <el-tag :type="getStatusType(scope.row.status)"
                      >PENDING</el-tag
                    >
                  </span>
                  <span class="el-dropdown-link" v-else>
                    <el-tag :type="getStatusType(scope.row.status)">{{
                      scope.row.status
                    }}</el-tag>
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="ACTIONS" fixed="right" min-width="120">
              <template v-slot="scope">
                <ul class="listings__action-buttons">
                  <li @click="goToPendingCaseView(scope.row._id)">
                    <el-button class="view-button" title="View Case">
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/view-icon.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/view-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li
                    v-if="
                      hasPermission('pending_genetic_cases', 'convert') &&
                      scope.row.status != 'COMPLETE' &&
                      scope.row.status != 'REJECTED'
                    "
                  >
                    <el-button
                      @click="convertToCase(scope.row)"
                      class="approval-button"
                      title="Convert To Case"
                    >
                      <img
                        src="@/assets/images/icons/actions/convert-to-case-inactive.svg"
                        alt="icon"
                        class="action-inactive"
                      />
                      <img
                        src="@/assets/images/icons/actions/convert-to-case-active.svg"
                        class="action-active"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li
                    v-if="
                      hasPermission('pending_genetic_cases', 'convert') &&
                      scope.row.status != 'COMPLETE' &&
                      scope.row.status != 'REJECTED'
                    "
                  >
                    <el-button
                      class="reject-button"
                      @click="onReject(scope.row)"
                      title="Reject Case"
                    >
                      <img
                        src="@/assets/images/icons/actions/reject-icon.svg"
                        alt="icon"
                        class="action-inactive"
                      />
                      <img
                        src="@/assets/images/icons/actions/reject-icon-active.svg"
                        class="action-active"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="listings listings__pagination">
          <el-pagination
            v-model:currentPage="page"
            v-model:page-size="pageSize"
            :page-sizes="[25, 50, 75, 100]"
            layout=" prev, pager, next, jumper, sizes ,total"
            :total="casesTable.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>

    <div class="default-modal">
      <el-dialog
        v-model="askRejectReason"
        :before-close="handleRejectClose"
        width="30%"
      >
        <div class="dialog-header">
          <span class="header-part"> Reason For Rejection </span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="handleRejectClose"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-date">
          <el-input
            type="textarea"
            rows="3"
            v-model="reasonForRejection"
            placeholder="Enter Reason For Rejection"
          ></el-input>
          <p><b>Note:</b> Minimum 3 characters is required</p>
          <div class="pt-5" style="color: #273480">
            <span style="font-weight: 500"> Rejected By : </span>
            <span style="font-weight: 500"
              >{{
                getAuthenticatedUser.first_name +
                " " +
                getAuthenticatedUser.last_name
              }}
            </span>
          </div>
          <span class="dialog-footer">
            <el-button class="cancel-button" @click="handleRejectClose"
              >Cancel</el-button
            >
            <el-button
              class="blue-save-button"
              :disabled="checkReasonForRejection"
              @click="rejectCase"
              :loading="loading"
              >Reject
            </el-button>
          </span>
        </div>
      </el-dialog>
    </div>
    <div class="default-modal" v-if="openConvertToCase">
      <el-dialog
        width="30%"
        v-model="openConvertToCase"
        :before-close="handleClose"
      >
        <div class="dialog-header">
          <span class="header-part"> Convert to Case </span>
          <el-button @click="handleClose">
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>

        <div class="popup-date">
          <el-form :model="caseForm" ref="convertionForm" :rules="caseRules">
            <el-form-item prop="reasonForConvertion" class="pb-0">
              <el-input
                type="textarea"
                rows="3"
                placeholder="Please enter reason for convert"
                v-model="caseForm.reasonForConvertion"
              ></el-input>
              <div class="pt-5" style="color: #273480">
                <span style="font-weight: 500"> Approved By: </span>
                <span style="font-weight: 500"
                  >{{
                    getAuthenticatedUser.first_name +
                    " " +
                    getAuthenticatedUser.last_name
                  }}
                </span>
              </div>
              <p><b>Note:</b> Minimum 3 characters is required</p></el-form-item
            >
          </el-form>

          <div class="dialog-footer">
            <el-button class="cancel-button" @click="handleClose"
              >Cancel</el-button
            >
            <el-button
              class="blue-save-button"
              :disabled="checkReasonForConvertion"
              @click="convertToRegularCase('convertionForm')"
              :loading="loading"
              >Submit</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import caseConstants from "@/config/constants/caseConstants";
import { casesSearchOptions } from "@/config/constants/searchConstants";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";

import FilterHelper from "@/mixins/FilterHelper";
import caseDataHelper from "@/mixins/caseDataHelper";
import AppHelper from "@/mixins/AppHelper";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";

export default {
  name: "allCases",
  components: {},
  props: {
    selectedStatuses: {
      default: [],
    },
  },
  computed: {
    ...mapGetters("pendingCases", [
      "getAllPendingCases",
      "getCaseConvertionStatus",
      "getCaseRejectionStatus",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    checkReasonForConvertion() {
      if (
        this.caseForm.reasonForConvertion &&
        this.caseForm.reasonForConvertion.trim().length >= 3
      ) {
        return false;
      }

      return true;
    },
    checkReasonForRejection() {
      if (
        this.reasonForRejection &&
        this.reasonForRejection.trim().length >= 3
      ) {
        return false;
      }
      return true;
    },

    getStatusType() {
      return (statusVal) => {
        const status = statusVal.trim();
        if (status == "ACTIVE") {
          return "default";
        } else if (status == "COMPLETE") {
          return "success";
        } else if (status == "TEST_REPORTS") {
          return "warning";
        } else if (status == "ARCHIVED" || status == "REJECTED") {
          return "danger";
        } else {
          return "";
        }
      };
    },

    ...mapGetters("hospitals", ["getAllHospitals"]),
    ...mapGetters("physicians", ["getAllPhysicians"]),
    getCaseTypes() {
      return (caseTypes) => {
        return (caseTypes || []).map((e) => this.caseTypeMap[e]);
      };
    },
  },
  mixins: [FilterHelper, UserPermissionsHelper, AppHelper, caseDataHelper],
  data() {
    return {
      scrollPosition: null,
      openConvertToCase: false,
      caseForm: {
        reasonForConvertion: "",
      },
      caseRules: {
        reasonForConvertion: [
          {
            required: true,
            message: "Invalid  reason",
            pattern: /^.\S/,
            trigger: "blur",
          },
        ],
      },
      caseId: "",
      reasonForRejection: "",
      selectedPendingCase: {},
      askRejectReason: false,
      casesTable: {},
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      page: 1,
      pageSize: 25,
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      search_string: "",
      date_of_birth: "",
      hospital: "",
      physician: "",
      searchBy: "",
      loading: false,
      value: "",
      orderBy: "created_at",
      orderType: "DESC",
      caseType: "",
      caseTypes: caseConstants.CASE_TYPES_DATA,
      searchOptions: casesSearchOptions,
      // searchByKey: ["PatientName", "accession_id", "EMR"],
      selectedCaseIds: [],
      selectedCaseId: null,
      takeStatusChangeReasonDialog: false,
      takeStatusChangeReasonDialogTitle: "",
      hospitalsData: {},
      physiciansData: {},
      hospitalsLoading: false,
      physiciansLoading: false,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    onReject(selectedCase) {
      this.selectedPendingCase = selectedCase;
      this.askRejectReason = true;
    },
    handleRejectClose() {
      this.askRejectReason = false;
      this.reasonForRejection = "";
    },
    async rejectCase() {
      try {
        this.loading = true;
        let params = {
          case_id: this.selectedPendingCase._id,
          converting_reason: this.reasonForRejection,
        };
        await this.$store.dispatch("pendingCases/rejectPendingCase", params);
        if (this.getCaseRejectionStatus) {
          this.askRejectReason = false;

          successNotification("Case Rejection Successfully");
          this.reasonForRejection = "";
          await this.fetchAllPendingCases(this.page);
          this.askRejectReason = false;
        } else {
          errorNotification("Case Rejection failed");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    convertToRegularCase(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.convertingToRegularCase();
        } else {
          return false;
        }
      });
    },
    async convertingToRegularCase() {
      this.loading = true;

      let params = {
        converting_reason: this.caseForm.reasonForConvertion,
        case_id: this.caseId,
      };
      await this.$store.dispatch("pendingCases/convertToCase", params);
      if (this.getCaseConvertionStatus) {
        successNotification("Convert To Case Successfully");
        this.caseForm.reasonForConvertion = "";
        await this.fetchAllPendingCases(this.page);
        this.openConvertToCase = false;
      }
      this.loading = false;
    },
    convertToCase(patient) {
      this.openConvertToCase = true;
      this.caseId = patient._id;
    },
    handleClose() {
      this.openConvertToCase = false;
      this.caseForm.reasonForConvertion = "";
    },

    clearInputSearch() {
      if (
        this.hospital ||
        this.physician ||
        this.date_of_birth ||
        this.caseType
      ) {
        this.hospital = "";
        this.physician = "";
        this.date_of_birth = "";
        this.caseType = "";
        this.isSearchByInput = "";
        this.page = 1;
        this.fetchAllPendingCases(1);
      }
    },

    async initData() {
      this.setQueryParamsData();
      await Promise.all([this.fetchAllPendingCases()]);
    },
    setQueryParamsData() {
      this.page = this.$route.query.selectedPage
        ? parseInt(this.$route.query.selectedPage)
        : 1;
      this.pageSize = this.$route.query.selectedPageSize
        ? parseInt(this.$route.query.selectedPageSize)
        : 25;
      if (this.$route.query.selectedString) {
        this.search_string = this.$route.query.selectedString;
      }
      if (this.$route.query.selectedCase) {
        this.caseType = this.$route.query.selectedCase;
      }
      if (this.$route.query.selectedCaseId) {
        this.selectedCaseId = this.$route.query.selectedCaseId;
      }
      if (this.$route.query.selectedSearchBy) {
        this.searchBy = this.$route.query.selectedSearchBy;
      }
      if (this.$route.query.selectedPhysician) {
        this.fetchAllPhysicians();
        this.physician = this.$route.query.selectedPhysician;
      }
      if (this.$route.query.selectedHospital) {
        this.fetchAllHospitals();
        this.hospital = this.$route.query.selectedHospital;
      }
      if (this.$route.query.selectedDateOfBirth) {
        this.date_of_birth = this.$route.query.selectedDateOfBirth;
      }
      if (this.$route.query.selectedOrderBy) {
        this.orderBy = this.$route.query.selectedOrderBy;
      }
      if (this.$route.query.selectedOrderType) {
        this.orderType = this.$route.query.selectedOrderType;
      }
    },
    async fetchAllPhysicians() {
      this.physiciansLoading = true;
      await this.$store.dispatch("physicians/fetchAllPhysicians", {
        get_all: true,
      });
      this.physiciansData = JSON.parse(JSON.stringify(this.getAllPhysicians));
      this.physiciansLoading = false;
    },
    async fetchAllHospitals() {
      this.hospitalsLoading = true;
      await this.$store.dispatch("hospitals/fetchAllHospitals", {
        get_all: true,
      });
      this.hospitalsData = JSON.parse(JSON.stringify(this.getAllHospitals));
      this.hospitalsLoading = false;
    },
    prepareParams() {
      const params = {
        page: this.page,
        limit: this.pageSize,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      if (this.date_of_birth) {
        const date_of_birth = moment(this.date_of_birth).format("YYYY-MM-DD");

        params.date_of_birth = date_of_birth;
      }
      if (this.hospital) {
        params.hospital = this.hospital;
      }
      if (this.caseType) {
        params.case_type = this.caseType;
      }
      if (this.physician) {
        params.ordering_physician = this.physician;
      }
      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      params.status = this.selectedStatuses;
      return params;
    },
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "created_at";
        this.orderType = "DESC";
      }
      this.fetchAllPendingCases();
    },
    getAutoCompleteCases() {
      setTimeout(() => {
        this.page = 1;
        this.fetchAllPendingCases();
      }, 600);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchAllPendingCases();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchAllPendingCases();
    },
    async fetchAllPendingCases() {
      try {
        let params = this.prepareParams();
        this.loading = true;
        await this.$store.dispatch("pendingCases/fetchAllPendingCases", params);
        this.casesTable = JSON.parse(JSON.stringify(this.getAllPendingCases));
        this.updateQueryParams(params);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    goToCaseView(case_id) {
      this.$router.push({
        name: "CaseView",
        params: { case_id: case_id },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    goToPendingCaseView(caseId) {
      this.$router.push({
        name: "PendingCaseView",
        params: { case_id: caseId },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedPage: params.page,
        selectedCase: params.case_type,
        selectedHospital: params.hospital,
        selectedDateOfBirth: params.date_of_birth,
        selectedPhysician: params.ordering_physician,
        selectedString: params.search_string,
        selectedPageSize: params.limit,
        selectedOrderBy: params.order_by,
        selectedOrderType: params.order_type,
        selectedCaseId: this.$route.query.selectedCaseId,
        selected_panel: this.$route.query.selected_panel,
        selectedSearchBy: this.searchBy || undefined,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
  },
};
</script>

<style lang="scss">
#all-approval-pending-cases {
  .stickyNavbar {
    padding-bottom: 5px !important;
    .container-default {
      padding-top: 0;
    }
  }
}
</style>
