export default {
  methods: {
    updateQueryParam(routeName, params = {}, reset = false) {
      if (!routeName) return;
      if (reset) {
        this.$router
          .replace({
            name: routeName,
            query: {},
          })
          .catch(() => {});
      } else if (params && Object.keys(params).length) {
        let queryParams = {};
        if (this.$route.query.name && routeName != this.$route.query.name) {
          queryParams = { ...queryParams, ...params };
        } else {
          queryParams = { ...this.$route.query, ...params };
        }
        this.$router
          .replace({
            name: routeName,
            query: queryParams,
          })
          .catch(() => {});
      }
    },
  },
};
