import caseConstants from "@/config/constants/caseConstants";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      caseDataExportFields: [
        {
          label: "Case Type",
          value: "case_types",
        },
        {
          label: "Accession ID",
          value: "accession_id",
        },
        {
          label: "Bill To",
          value: "billing_type",
        },
        {
          label: "Patient Name",
          value: "patient_info.name",
        },
        {
          label: "Patient DOB",
          value: "patient_info.date_of_birth",
        },
        {
          label: "Patient Gender",
          value: "patient_info.gender",
        },
        {
          label: "Patient Age",
          value: "patient_info.age",
        },
        {
          label: "Patient Phone",
          value: "patient_info.phone",
        },
        {
          label: "Patient Address",
          value: "patient_info.address",
        },
        {
          label: "Ordering Facility",
          value: "hospital.name",
        },
        {
          label: "Ordering Facility Phone",
          value: "hospital.phone",
        },
        {
          label: "Ordering Physician",
          value: "ordering_physician.name",
        },
        {
          label: "Date of Collection(DOC)",
          value: "collection_date",
        },
        {
          label: "Date of Received(DOS)",
          value: "received_date",
        },
        {
          label: "Covid Status",
          value: "covid_status",
        },
      ],

      hospitalDataExportFields: [],
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
    };
  },
  async mounted() {

  },
  computed: {
    ...mapGetters("profile", ["getTestPanelsData"]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("hospitals", ["getSingleHospital"]),
  },
  methods: {
    checkHospitalHasEmailNotifications(caseType) {
      if (
        this.getSingleHospital &&
        this.getSingleHospital.settings &&
        this.getSingleHospital.settings.patient_report_settings &&
        this.getSingleHospital.settings.patient_report_settings
          .send_finalized_report_to_patient &&
        this.getSingleHospital.settings.patient_report_settings
          .email_sending_data &&
        this.getSingleHospital.settings.patient_report_settings
          .email_sending_data.status
      ) {
        let hasAccess =
          this.getSingleHospital &&
          this.getSingleHospital.settings &&
          this.getSingleHospital.settings.patient_report_settings &&
          this.getSingleHospital.settings.patient_report_settings
            .send_finalized_report_to_patient &&
          this.getSingleHospital.settings.patient_report_settings
            .email_sending_data &&
          this.getSingleHospital.settings.patient_report_settings
            .email_sending_data.status;
        if (hasAccess) {
          let hasCaseTypeRestriction =
            this.getSingleHospital.settings.patient_report_settings
              .email_sending_data.for_all_case_types;
          if (hasAccess && hasCaseTypeRestriction) {
            return true;
          } else if (hasAccess && !hasCaseTypeRestriction) {
            let emailCaseTypes =
              this.getSingleHospital.settings.patient_report_settings
                .email_sending_data.for_specific_case_types;
            if (emailCaseTypes.indexOf(caseType) > -1) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    checkHospitalHasSmsNotifications(caseType) {
      if (
        this.getSingleHospital &&
        this.getSingleHospital.settings &&
        this.getSingleHospital.settings.patient_report_settings &&
        this.getSingleHospital.settings.patient_report_settings
          .send_finalized_report_to_patient &&
        this.getSingleHospital.settings.patient_report_settings
          .sms_sending_data &&
        this.getSingleHospital.settings.patient_report_settings.sms_sending_data
          .status
      ) {
        let hasAccess =
          this.getSingleHospital &&
          this.getSingleHospital.settings &&
          this.getSingleHospital.settings.patient_report_settings &&
          this.getSingleHospital.settings.patient_report_settings
            .send_finalized_report_to_patient &&
          this.getSingleHospital.settings.patient_report_settings
            .sms_sending_data &&
          this.getSingleHospital.settings.patient_report_settings
            .sms_sending_data.status;

        if (hasAccess) {
          let hasCaseTypeRestriction =
            this.getSingleHospital.settings.patient_report_settings
              .sms_sending_data.for_all_case_types;
          if (hasAccess && hasCaseTypeRestriction) {
            return true;
          } else if (hasAccess && !hasCaseTypeRestriction) {
            let smsCaseTypes =
              this.getSingleHospital.settings.patient_report_settings
                .sms_sending_data.for_specific_case_types;
            if (smsCaseTypes.indexOf(caseType) > -1) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    caseTypesText(caseTypes) {
      return caseTypes
        .map((type) => this.caseTypeMap[type])
        .join(", ")
        .toUpperCase();
    },
    getFormattedValues(params) {
      for (let [key, value] in Object.entries(params)) {
        if (!value) {
          delete params[key];
        }
      }
      return params;
    },
    getUserBasedCaseTypes(caseTypes = []) {
      let userCaseTypes = caseTypes;
      if (
        this.getAuthenticatedUser.allowed_case_types &&
        this.getAuthenticatedUser.allowed_case_types.length
      ) {
        let data = this.getAuthenticatedUser.allowed_case_types.map((e) => e);

        userCaseTypes = userCaseTypes.filter((caseType) => {
          if (data.indexOf(caseType.value) != -1) {
            return true;
          } else {
            return false;
          }
        });
      }
      return userCaseTypes;
    },
    getLabBasedCaseTypes(caseTypes = []) {
      let caseTypeData = caseTypes;
      let data = this.getTestPanelsData.map((e) => e.code);
      caseTypeData = caseTypeData.filter((caseType) => {
        if (data.indexOf(caseType.value) != -1) {
          return true;
        } else {
          return false;
        }
      });
      return caseTypeData;
    },
  },
};
