import moment from "moment";
export default {
  data() {
    return {
    };
  },
  computed: {},
  methods: {
    getFormattedValues(params) {
      for (let [key, value] of Object.entries(params)) {
        if (!value) {
          delete params[key];
        }
      }
      return params;
    },

    captilize(name) {
      name = name.toLowerCase();
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    removeDuplicatesByProperty(data, property) {
      let dataMap = {};
      let finalData = [];

      data.forEach((element) => {
        if (element && !dataMap[element[property]]) {
          finalData.push(element);
          dataMap[element[property]] = 1;
        }
      });

      return finalData;
    },
    divideArray(list) {
      let half = list.length / 2;
      if (list.length % 2 == 0) {
        return [list.slice(0, half), list.slice(half, list.length)];
      } else {
        return [list.slice(0, half + 1), list.slice(half + 1, list.length)];
      }
    },
    checkDateValue() {
      this.$nextTick(() => {
        let pickerBodies = document.getElementsByClassName(
          "el-date-picker__editor-wrap"
        );
        for (let pickerBody of pickerBodies) {
          let inputs = pickerBody.getElementsByClassName("el-input__inner");
          for (let input of inputs) {
            input.setAttribute("readonly", "");
          }
        }
      });
    },

    arrayInterSection(array1, array2) {
      return array1.filter(function (n) {
        return array2.indexOf(n) !== -1;
      });
    },
  },
};
