export const casesSearchOptions = [
  {
    value: "case_types",
    label: "Case Type",
  },
  {
    value: "hospital",
    label: "Ordering Facility",
  },
  {
    value: "ordering_physician",
    label: "Physician",
  },
  {
    value: "Patient DOB",
    label: "Patient DOB",
  },
]
export const casesExtraSearchOptions = [
  {
    value: "accession_id",
    label: "Accession",
    active: true,
  },
  {
    label: "Assigned to",
    value: "test_performed_by",
    active: true,
  },
  {
    value: "case_types",
    label: "Case Type",
    active: true,
  },
  {
    value: "emr",
    label: "EMR #",
    active: true,
  },
  {
    value: "billing_sub_status",
    label: "Exception",
    active: true,
  },
  {
    value: "hospital_marketers",
    label: "Sales Rep",
    active: true,
  },
  {
    value: "hospital",
    label: "Ordering Facility",
    active: true,
  },
  {
    value: "ordering_physician",
    label: "Physician",
    active: true,
  },
  {
    value: "patient_name",
    label: "Patient Name",
    active: true,
  },
  {
    value: "Patient DOB",
    label: "Patient DOB",
    active: true,
  },
  {
    value: "Sendout",
    label: "Sendout",
    active: true,
  },
]
export const pregnencyOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
]
export const ordersSearchOptions = [
  {
    value: "case_types",
    label: "Case Type",
  },
  {
    value: "ordering_physician",
    label: "Physician",
  },
  {
    value: "hospital",
    label: "Ordering Facility",
  },
  {
    value: "case_types",
    label: "Case Type",
  },
  {
    value: "ordering_physician",
    label: "Physician",
  },
  {
    label: "Territory Manager",
    value: "hospital_marketers",
  },
  {
    value: "hospital",
    label: "Ordering Facility",
  },
  {
    value: "patient_name",
    label: "Patient Name",
  },
  {
    value: "Patient DOB",
    label: "Patient DOB",
  },

]
export const ordersExtraSearchOptions = [
  {
    value: "case_types",
    label: "Case Type",
    active: true,
  },
  {
    label: "Sales Rep",
    value: "hospital_marketers",
    active: true,
  },
  {
    value: "hospital",
    label: "Ordering Facility",
    active: true,
  },
  {
    value: "ordering_physician",
    label: "Physician",
    active: true,
  },
  {
    value: "patient_name",
    label: "Patient Name",
    active: true,
  },
  {
    value: "Patient DOB",
    label: "Patient DOB",
    active: true,
  },
  // {
  //   value: "Future Orders",
  //   label: "Future Orders",
  //   active: true,
  // },
]
export const sendOutSearchOptions = [
  {
    value: "case_types",
    label: "Case Type",
  },
  {
    value: "hospital",
    label: "Ordering Facility",
  },
  {
    value: "ordering_physician",
    label: "Physician",
  },
  {
    value: "Patient DOB",
    label: "Patient DOB",
  },

]
export const receivedSendOutSearchOptions = [
  {
    value: "case_types",
    label: "Case Type",
  },

  {
    value: "Patient DOB",
    label: "Patient DOB",
  },
]
export const emrSearchOptions = [
  {
    value: "case_types",
    label: "Case Type",
    active: true,
  },
  {
    value: "emr_client",
    label: "EMR",
    active: true,
  },
  {
    label: "Sales Rep",
    value: "hospital_marketers",
    active: true,
  },
  {
    value: "hospital",
    label: "Ordering Facility",
    active: true,
  },
  {
    value: "ordering_physician",
    label: "Physician",
    active: true,
  },
  {
    value: "Patient DOB",
    label: "Patient DOB",
    active: true,
  },
  {
    label: "Phlebio Orders",
    value: "send_to_phlebio",
    active: true,
  },
]
export const physiciansSearchOptions = [
  {
    value: "name",
    label: "Physician",
  },
  {
    value: "npi_code",
    label: "Npi Code",
  },
  {
    value: "specializations",
    label: "Specializations",
  },
  {
    value: "fax",
    label: "Fax",
  },
  {
    value: "phone",
    label: "Phone",
  },
]
export const hospitalsSearchOptions = [
  {
    value: "code",
    label: "Code",
  },
  {
    value: "name",
    label: "Ordering Facility",
  },
  {
    value: "fax",
    label: "Fax",
  },
  {
    value: "phone",
    label: "Phone",
  },
]
export const usersSearchOptions = [
  {
    value: "hospital",
    label: "ORDERING FACILITY",
  },
  {
    value: "user_type",
    label: "User Type",
  },
  {
    value: "name",
    label: "Name",
  },
  {
    value: "username",
    label: "Username",
  },
]
export const labSearchOptions = [
  {
    value: "labName",
    label: "Lab Name",
  },
  {
    value: "labCode",
    label: "Lab Code",
  },
  {
    value: "Email",
    label: "Email",
  },
  {
    value: "PhoneNo",
    label: "Phone No",
  },
]
export const userSearchOptions = [
  {
    value: "user",
    label: "User",
  },
  {
    value: "username",
    label: "Username",
  },
  {
    value: "role",
    label: "Role",
  },
]
export const bloodSearchOptions = [
  {
    value: "search_string",
    label: "Search Assay Name",
  },
  {
    value: "search_instrument",
    label: "Select Instrument",
  },
]
export const billingCategoryOptions = [
  "AR Coding",
  "Authorization / Referral",
  "Billed",
  "Billed with Exception",
  "Client",
  "COB Information",
  "Coding",
  "Covered by another payer",
  "Credit Balance",
  "Duplicate",
  "Exception",
  "Inclusive",
  "Inactive coverage",
  "Insurance Follow Up",
  "NDC",
  "Need Primary EOB",
  "Need Additional Information",
  "No claim on file",
  "Non Covered Services",
  "Not billed yet",
  "Offsets Adjustment",
  "On hold",
  "Others",
  "Patient",
  "Paid",
  "Past filing/appeal limit",
  "Patient Info",
  "Patient Responsibility",
  "Posting Corrections",
  "Provider Info",
  "Recoupment",
  "Resolved",
  "Submission Error",
  "Unbilled",
]
export const billingSubCategoryOptions = {
  Billed: ["Billed"],
  Unbilled: ["Claim process pending", "Coding Pending"],
  Exception: [
    "DOS mismatch",
    "Exception Resolved",
    "Invalid subscriber ID/ patient name",
    "Missing /Invalid ICD",
    "Policy Inactive",
    "Provider/ Patient signature missing",
    "Panel not marked/Mismatch",
    "Requisition/ Additional document  missing",
  ],
  "On hold": [
    "CPT/insurance guidelines Hold",
    "Credential hold",
    "Deductible not met",
  ],
  "Billed with Exception": [
    "ABR not marked",
    "Organisms mismatch b/w req and report",
    "Panel not marked/Mismatch",
  ],
  Patient: [
    "Active / valid insurance details.",
    "Paid to patient",
    "Patient responsibility",
    "Self Pay",
  ],
  Client: ["Client bill", "Send out Cases"],
  Coding: ["For Coding"],
  "AR Coding": ["Coding issue", "Inclusive / Global / Mutually Exclusive", "Invalid/Incorrect CPT Code", "Invalid/Incorrect ICD Code", "Invalid/Missing Modifier", "Medically Not Necessary"],
  "Authorization / Referral": ["Missing Authorization", "Authorization not valid", "Referal missing"],
  "COB Information": ["COB Information"],
  "Covered by another payer": ["Covered by another payer"],
  "Credit Balance": ["Ins overpayment", "Pt overpayment"],
  Duplicate: ["Duplicate"],
  Inclusive: ["Inclusive with inpatient payments"],
  "Inactive coverage": ["coverage terminated"],
  "Need Primary EOB": ["Policy not eligible for DOS", "Need Primary EOB"],
  "Insurance Follow Up": ["Claim Sent For Reprocessing", "Reached Voice mail", "Recently Submitted/Worked", "Email request", "Claim in process", "Reached Fax Back", "Recently followed up/ Worked", "Ringing no response", "Call back request left with Operator"],
  "Need Additional Information": ["Need Additional Information", "Accident Details", "Need Medical Records"],
  "No claim on file": ["No claim on file"],
  "Non Covered Services": ["Maximum Benefit Reached", "Non Covered Services", "Non Covered Services Under Patient Plan"],
  "Not billed yet": ["Not billed"],
  "Offsets Adjustment": ["Offsets Adjustment"],
  Others: ["Others"],
  Paid: ["Paid - Awaiting EOB", "Paid Recently", "Paid but check not yet issued", "Paid to Incorrect Address", "Posting Corrections", "Primary & sec paid - Balance is with tertiary ins", "Primary Paid - Balance is with Sec Ins", "Primary Paid more than allowed", "Pending ERA to post", "Insurance Paid - Need to post payment", "Under Paid", "Paid", "Partially Paid", "Paid not posted"],
  "Past filing/appeal limit": ["Past Filling Limit", "Past Appeal Limit"],
  "Patient Info": ["Need Additional Info", "Patient cannot be identified", "Patient ID not match with Name", "Letter sent to Patient for additional info", "Patient Info"],
  "Patient Responsibility": ["Balance pending with PT", "Out of network deductible", "Paid to Patient", "PT Coins/Copay", "PT deductible"],
  "Posting Corrections": ["Posting Corrections"],
  "Provider Info": ["Invalid/Missing Provider ID", "License Issue", "Need Additional Info", "Needs W-9 form", "Provider Info", "Provider not eligible for the service", "Provider out of network", "Referring Physician", "CLIA No"],
  Recoupment: ["Recoupment"],
  "Submission Error": ["Submission Error"],
  NDC: ["NDC missing on claim", "NDC invalid on claim"],
  Resolved: ["Zero Balance"]
}
export const phlebioSearchOptions = [
  {
    value: "case_types",
    label: "Case Type",
    active: true,
  },
  {
    value: "hospital",
    label: "Ordering Facility",
    active: true,
  },
  {
    value: "ordering_physician",
    label: "Physician",
    active: true,
  },
  {
    value: "Patient DOB",
    label: "Patient DOB",
    active: true,
  },
]
export const CPTCodes = {
  36415: [11],
  80048: [11],
  80050: [45],
  80051: [9],
  80053: [14],
  80061: [17],
  80069: [11],
  80074: [60],
  80076: [11],
  80150: [19],
  80158: [23],
  80162: [17],
  80164: [17],
  80177: [17],
  80178: [9],
  80185: [17],
  80197: [18],
  80202: [17],
  80280: [49],
  80306: [22],
  80307: [78],
  80323: [41],
  80324: [31],
  80336: [41],
  80345: [33],
  80346: [33],
  80348: [32],
  80349: [27],
  80353: [25],
  80354: [31],
  80355: [19],
  80356: [32],
  80358: [27],
  80359: [21],
  80360: [22],
  80361: [34],
  80364: [32],
  80365: [29],
  80366: [17],
  80367: [26],
  80368: [21],
  80370: [24],
  80371: [29],
  80372: [26],
  80373: [25],
  80414: [65],
  81000: [85],
  81001: [4],
  81002: [4],
  81015: [38],
  81225: [365],
  81226: [564],
  81227: [219],
  81230: [219],
  81231: [219],
  81238: [220],
  81240: [83],
  81241: [92],
  81256: [82],
  81291: [82],
  81327: [240],
  81328: [219],
  81332: [55],
  81335: [219],
  81355: [111],
  81401: [172],
  81479: [53],
  82040: [7],
  82043: [8],
  82088: [51],
  82103: [17],
  82104: [19],
  82140: [19],
  82150: [9],
  82164: [19],
  82247: [7],
  82248: [7],
  82270: [6],
  82274: [20],
  82300: [30],
  82306: [37],
  82308: [34],
  82310: [7],
  82374: [7],
  82378: [24],
  82435: [6],
  82465: [6],
  82533: [21],
  82550: [9],
  82553: [15],
  82565: [7],
  82570: [7],
  82607: [19],
  82627: [28],
  82670: [35],
  82728: [18],
  82746: [19],
  82784: [12],
  82785: [21],
  82947: [5],
  82951: [17],
  82977: [9],
  83001: [24],
  83002: [24],
  83013: [85],
  83036: [13],
  83090: [23],
  83516: [15],
  83520: [22],
  83525: [15],
  83540: [9],
  83550: [11],
  83605: [15],
  83615: [8],
  83655: [16],
  83690: [9],
  83695: [18],
  83698: [58],
  83718: [11],
  83721: [14],
  83735: [9],
  83825: [21],
  83880: [50],
  83883: [17],
  83937: [38],
  83970: [52],
  83992: [34],
  84075: [7],
  84100: [6],
  84132: [6],
  84134: [19],
  84144: [27],
  84145: [35],
  84146: [25],
  84153: [23],
  84154: [23],
  84155: [5],
  84156: [5],
  84244: [28],
  84270: [28],
  84295: [7],
  84305: [27],
  84402: [32],
  84403: [33],
  84410: [65],
  84432: [21],
  84436: [9],
  84439: [12],
  84443: [21],
  84445: [64],
  84449: [23],
  84450: [7],
  84460: [7],
  84466: [16],
  84478: [8],
  84479: [9],
  84480: [18],
  84481: [22],
  84482: [20],
  84484: [16],
  84520: [5],
  84540: [7],
  84550: [6],
  84681: [27],
  84702: [19],
  84703: [10],
  85018: [3],
  85025: [10],
  85045: [5],
  85240: [23],
  85270: [23],
  85300: [15],
  85303: [18],
  85306: [20],
  85307: [20],
  85379: [13],
  85384: [13],
  85385: [19],
  85610: [6],
  85613: [12],
  85651: [6],
  85652: [4],
  85660: [7],
  85670: [8],
  85705: [13],
  85730: [8],
  85732: [9],
  86003: [7],
  86036: [16],
  86037: [16],
  86038: [16],
  86140: [7],
  86141: [17],
  86147: [32],
  86160: [15],
  86225: [18],
  86231: [16],
  86235: [23],
  86258: [16],
  86300: [27],
  86301: [27],
  86304: [27],
  86308: [7],
  86317: [19],
  86334: [28],
  86364: [15],
  86376: [19],
  86431: [8],
  86480: [78],
  86592: [6],
  86593: [6],
  86635: [15],
  86663: [17],
  86664: [20],
  86665: [23],
  86677: [22],
  86695: [17],
  86696: [25],
  86704: [16],
  86705: [15],
  86706: [14],
  86707: [15],
  86708: [16],
  86709: [15],
  86710: [17],
  86735: [17],
  86762: [18],
  86765: [17],
  86769: [53],
  86780: [17],
  86787: [17],
  86800: [20],
  86803: [18],
  86900: [4],
  86901: [4],
  87040: [13],
  87070: [11],
  87075: [12],
  87081: [9],
  87086: [11],
  87150: [44],
  87186: [11],
  87188: [9],
  87205: [6],
  87207: [8],
  87324: [15],
  87340: [13],
  87341: [13],
  87389: [31],
  87471: [44],
  87476: [44],
  87481: [44],
  87486: [44],
  87491: [44],
  87496: [44],
  87498: [44],
  87500: [44],
  87506: [161],
  87507: [329],
  87521: [44],
  87529: [44],
  87532: [44],
  87536: [107],
  87541: [44],
  87551: [61],
  87556: [63],
  87563: [54],
  87581: [44],
  87591: [44],
  87623: [44],
  87624: [44],
  87625: [51],
  87631: [179],
  87633: [521],
  87635: [65],
  87636: [179],
  87637: [179],
  87640: [44],
  87641: [44],
  87651: [44],
  87653: [44],
  87661: [44],
  87798: [44],
  87799: [54],
  87801: [88],
  88142: [26],
  88175: [34],
  G0103: [25],
  G0480: [144],
  G0481: [196],
  G0482: [249],
  G0483: [309],
  U0001: [45],
  U0002: [65],
}
export const exceptionCategories = [
  "DOS mismatch",
  "Exception Resolved",
  "Invalid subscriber ID/ patient name",
  "Missing /Invalid ICD",
  "Policy Inactive",
  "Provider/ Patient signature missing",
  "Panel not marked/Mismatch",
  "Requisition/ Additional document  missing",

]
